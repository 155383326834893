import { SET_ALERT, REMOVE_ALERT } from '../constants/alertConstants';
import { v4 as uuid } from 'uuid';

export const setAlert = (msg, alertType) => (dispatch) => {
  const id = uuid();
  dispatch({
    type: SET_ALERT,
    payload: { msg, alertType, id },
  });
};
