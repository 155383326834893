const formatError = (error) => {
  if (error instanceof Array) {
    let errorText = ``;
    error?.forEach((err) => (errorText += `${err} |`));
    return errorText.substring(0, errorText.length - 2);
  }

  let errorText = ``;
  for (const [key, value] of Object.entries(error)) {
    errorText += `|${key.toUpperCase()}| `;
    if (value instanceof Array) {
      for (let i = 0; i < value.length; i++) {
        errorText += `[${i + 1}] ${value[i]}, `;
      }
      errorText += ` `;
      return errorText.substring(0, errorText.length - 3);
    }

    errorText += value;
    errorText += ` `;
    return errorText;
  }
};

export default formatError;
