import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#C38A2D',
    },
    secondary: {
      main: '#00ACEE',
    },
    white: {
      main: '#FFFFFF',
    },
    common: {
      red: 'red',
      beige: 'black',
      white: 'white',
    },
    text: {
      primary: '#54595F',
      dark: '#000000',
    },
    // error: {
    //   main: '#68f436',
    // },
    // info: {
    //   main: '#fff506',
    // },
    // success: {
    //   main: '#ff2300',
    // },
    // link: {
    //   main: '#fff506',
    // },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    p: {
      fontFamily: '"Open Sans", sans-serif',
      color: '#54595F',
    },
    h1: {
      fontFamily: '"Barlow", sans-serif',
      color: '#C38A2D',
      fontSize: '50px',
      lineHeight: '48px',
      fontWeight: '600',
    },
    h2: {
      fontFamily: '"Barlow", sans-serif',
      color: '#C38A2D',
      fontSize: '34px',
      lineHeight: '48px',
      fontWeight: '600',
    },
  },
  testClasses: {
    background: 'red',
  },
});

export default theme;
