import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF203A' }} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF543B' }} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FF8139' }} />,
    label: 'Dissatisfied',
  },
  4: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FFA638' }} />,
    label: 'Dissatisfied',
  },
  5: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#FFC61F' }} />,
    label: 'Dissatisfied',
  },
  6: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#E3CB24' }} />,
    label: 'Dissatisfied',
  },
  7: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#B7D229' }} />,
    label: 'Dissatisfied',
  },
  8: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#8CD733' }} />,
    label: 'Dissatisfied',
  },
  9: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#08CA0A' }} />,
    label: 'Dissatisfied',
  },
  10: {
    icon: <StarIcon fontSize="inherit" sx={{ color: '#00964E' }} />,
    label: 'Dissatisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function StarRating({
  handleAnswerChange,
  questionId,
  masseuseId,
}) {
  // const [value, setValue] = React.useState(0);
  return (
    <div style={{ margin: '0px 0px 5px 5px' }}>
      <StyledRating
        defaultValue={0}
        max={10}
        IconContainerComponent={IconContainer}
        emptyIcon={<StarBorderIcon fontSize="inherit" />}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly={false}
        sx={{ fontSize: '3.8rem' }}
        onChange={(event, newValue) => {
          // setValue(newValue);
          handleAnswerChange(questionId, newValue, masseuseId);
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontWeight: '300',
          marginTop: '5px',
          width: '38rem',
        }}
      >
        <p>Very Dissatisfied</p>
        <p>Dissatisfied</p>
        <p>Neutral</p>
        <p>Satisfied</p>
        <p>Very Satisfied</p>
      </div>
    </div>
  );
}
