import {
  SUBMIT_SURVEY_FAIL,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_RESET,
  SUBMIT_SURVEY_SUCCESS,
} from '../constants/surveyConstants';

export const surveySubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case SUBMIT_SURVEY_REQUEST:
      return { loading: true };
    case SUBMIT_SURVEY_SUCCESS:
      return { loading: false, survey: action.payload, success: true };
    case SUBMIT_SURVEY_FAIL:
      return { loading: false, error: action.payload, success: false };
    case SUBMIT_SURVEY_RESET:
      return {};
    default:
      return state;
  }
};
