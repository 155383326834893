import jwtDecode from 'jwt-decode';

const isTokenValid = (token) => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

      return decodedToken.exp > currentTime;
    } catch (error) {
      // Handle invalid token or decoding error
      return false;
    }
  }

  return false; // Token is not provided
};

export default isTokenValid;
